<template>
  <div>
    <slot name="addTitle"></slot>
    <div class="cardForm">
      <input
        type="text"
        v-model.trim="cardName"
        @input="changeBtn"
        :placeholder="
          $store.state.language == 1 ? '持卡人姓名' : 'Cardholder Name'
        "
      />
      <input
        type="text"
        v-model="cardNum"
        @input="changeBtn(1)"
        :placeholder="
          $store.state.language == 1
            ? '信用卡號碼(0000 0000 0000 0000)'
            : 'Credit Card Number (0000 0000 0000 0000)'
        "
      />
      <div>
        <input
          type="number"
          @input="changeBtn(2)"
          v-model.trim="month"
          :placeholder="$store.state.language == 1 ? '月份' : 'Month'"
          ref="myMonth"
        />
        <input
          type="number"
          @input="changeBtn(3)"
          v-model.trim="year"
          :placeholder="$store.state.language == 1 ? '年份' : 'Year'"
          maxlength="2"
          ref="myYear"
        />
        <input
          type="number"
          @input="changeBtn"
          v-model.trim="cardCode"
          :placeholder="
            $store.state.language == 1 ? '信用卡保安編號(000)' : 'CVC(000)'
          "
          ref="mySafeNum"
        />
      </div>
    </div>
    <div class="saveCard" v-if="!show && $store.state.userInfo">
      <p class="checkbox" @click="saveCard">
        <i :class="{ active: save }"></i>
        <span>{{ CCXYK }}</span>
      </p>
      <div class="icon">
        <img src="@/assets/icon/card1.png" alt="" />
        <img src="@/assets/icon/card2.png" alt="" />
      </div>
    </div>
    <div class="bindBtn" :class="{ active: showBtn }" @click="bindCard">
      {{ btnText }}
    </div>
  </div>
</template>
<script>
import { postPaas } from "../../assets/utils/request";
import L from "../../assets/js/language";
export default {
  name: "BindCardCon",
  data() {
    return {
      cardName: "",
      cardNum: "",
      month: "",
      year: "",
      cardCode: "",
      showBtn: false,
      save: false,
      pageLink: "",
      CCXYK:
        this.$store.state.language == 1
          ? "儲存信用卡"
          : "Save This Credit Card",
      HQXXSB: "",
    };
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default() {
        return "綁定";
      },
    },
    saveC: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number || String,
      default: 0,
    },
    confirmAdd: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    changeBtn(type) {
      if (this.cardNum && this.month && this.year && this.cardCode) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
      if (type == 1 && this.cardNum.length == 19) {
        this.$refs.myMonth.focus();
      }
      if (type == 2 && this.month.length == 2) {
        this.$refs.myYear.focus();
      }
      if (type == 3 && this.year.length == 2) {
        this.$refs.mySafeNum.focus();
      }
    },
    async bindCard() {
      if (!this.showBtn) return;
      // 5123450000000008
      // 2223000000000007
      // 4508750015741019
      var params = {
        actionName: "candao.member.addCreditCardDirectly",
        content: {
          device: this.device,
          isMember: true,
          isBrowserCall: true,
        },
      };
     
      // var C = this.cardNum.replaceAll(" ", "");
      var C=this.cardNum.split(" ").join("");
      params.content.cardNumber = C;
      params.content.expiryMonth = this.month;
      params.content.expiryYear = this.year;
      params.content.securityCode = this.cardCode;
      params.content.cardHolder = this.cardName;
      params.content.isSave = this.save;
       if (!this.$store.state.userInfo) {
        params.content.isMember = false;
        params.content.userId = this.userId;
      } else {
        params.content.userId = this.$store.state.userInfo.userId;
        params.content.isSave = true;
      }

      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        if (this.saveC) {
          this.$emit("refreshCard");
        }
        this.$emit("closeBox", result.data);
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    saveCard() {
      this.save = !this.save;
    },
    setLanguage() {
      let T = this.$store.state.language == 2 ? "english" : "chinese";
      this.HQXXSB = L.HQXXSB[T];
    },
  },
  watch: {
    cardNum(val) {
      this.$nextTick(() => {
        this.cardNum = val.replace(/\s/g, "").replace(/....(?!$)/g, "$& ");
      });
    },
  },
  computed: {
    device() {
      var deviceId;
      var u = navigator.userAgent;
      if (u.indexOf("iPhone") > -1 || u.indexOf("iphone") > -1) {
        deviceId = "IOS";
      } else if (u.indexOf("Windows") > -1 || u.indexOf("windows") > -1) {
        deviceId = "WIN";
      } else if (u.indexOf("Android") > -1 || u.indexOf("android") > -1) {
        deviceId = "AND";
      } else if (u.indexOf("iPad") > -1 || u.indexOf("ipad") > -1) {
        deviceId = "IOS";
      } else if (u.indexOf("Mac") > -1 || u.indexOf("mac") > -1) {
        deviceId = "WIN";
      } else {
        deviceId = "Unknown device";
      }
      return deviceId;
    },
  },
  created() {
    this.setLanguage();
    var href = window.location.href.split("/");
    this.pageLink = href[0] + "//";
    for (var i = 1; i < href.length; i++) {
      this.pageLink += href[i];
      if (href[i]) {
        break;
      }
    }
  },
};
</script>
<style scoped>
.cardForm {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.cardForm input {
  width: 100%;
  height: 0.44rem;
  background: #f2f3f5;
  border-radius: 0.04rem;
  margin: 0;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #b1b1b3;
  padding: 0 0.08rem;
  box-sizing: border-box;
  border: none;
  outline: none;
}
.cardForm > input {
  margin-bottom: 0.12rem;
}
.cardForm div {
  display: flex;
  justify-content: space-between;
}
.cardForm div input:first-child,
.cardForm div input:nth-child(2) {
  width: 0.64rem;
  margin-right: 0.08rem;
}
.bindBtn {
  height: 0.44rem;
  border-radius: 0.08rem;
  background-color: #ddd;
  margin: 0.1rem 0.16rem 0;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.44rem;
  color: #808080;
  text-align: center;
}
.bindBtn.active {
  color: #fff;
  background-color: #e4022b;
}
.saveCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #030f14;
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkbox i {
  width: 0.16rem;
  height: 0.16rem;
  background: url("../../assets/icon/cycle.png") no-repeat center;
  background-size: cover;
  margin-right: 0.02rem;
}
.checkbox i.active {
  background: url("../../assets/icon/icon@2x.png") no-repeat center;
  background-size: cover;
}
.icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.icon img {
  height: 0.24rem;
  object-fit: cover;
}
.icon img + img {
  margin-left: 0.08rem;
}
</style>